@import "./normalize.css";

::selection {
  background-color: #906f07;
  color: #fff5d7;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffc820;
  color: #624b01;
  font-family: "B612 Mono", monospace;
  line-height: 1.6em;
  padding: 0 10vw;
  padding-bottom: 10vh;
}

main {
  padding-top: 10vh;
  text-align: center;
}

main.privacy {
  text-align: left;
}

.banana-menu {
  margin-top: -20px;
  transition: all ease-in-out 0.6s;
  transform: translateY(-100%);
}

.banana-menu-visible {
  transform: translateY(0px);
}

h1 {
  line-height: 3rem;
}

a {
  text-decoration: underline;
  color: #9e2a2a;
  padding: 12px 16px;
  outline-color: #219653;
  transition: outline-width ease-in 0.3s, outline-color ease-in-out 1s;
}
a:hover {
  color: #219653;
  outline-width: 3px;
}

a:focus {
  outline-width: 6px;
  outline-color: #219653;
  color: #219653;
}

a:active {
  outline-width: 12px;
  outline-color: #6f9621;
  color: #219653;
}

.banana-button {
  color: #9e2a2a;
  line-height: 4rem;
  border: 0;
  background-color: transparent;
  font-family: "B612 Mono", monospace;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  transition: outline-width ease-in 0.3s, outline-color ease-in-out 1s;
  outline-color: #219653;
}

.or {
  margin-top: 32px;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 67px;
}

.banana-button:hover {
  color: #219653;
  outline-width: 6px;
}

.banana-button:focus {
  outline-width: 16px;
  outline-color: #219653;
  color: #219653;
}

.banana-button:active {
  outline-width: 28px;
  outline-color: #6f9621;
  color: #219653;
}

.banana-button-pressed {
  outline-width: 30px;
}

.centerize {
  height: 0px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
}

.line {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 4px solid #624b01;
}

.faded {
  text-align: center;
  margin-top: 6rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.faded a {
  font-size: 0.8rem;
  text-decoration: none;
  color: #af860a;
}

.appstore-link {
  padding-top: 32px;
}
